import { useEffect, useRef, useState, useMemo } from 'react'
import { styled } from '@mui/material/styles'

import SearchForm from './SearchForm'
import SearchResults from './SearchResults'
import { withBreadcrumbs } from '@/components/lib/Breadcrumbs'
import { useAdvancedSearch } from '@/lib/features/Screening'

import { IEntitySearchParams } from '@/types'
import { useNotification } from '@/lib/notification'
import {
  WEAK_AKAS_DEFAULT,
  SLIDER_DEFAULT,
  FUZZY_DOB_DEFAULT_MONTHS_RANGE
} from '@/lib/constants/search'
import Alert from '@mui/material/Alert'
import { useLocation } from 'react-router-dom'
import {
  getDisabledDropDownCategories,
  useListSourcesDropDownOptions,
  selectedListSourcesOptionsIDs,
  translateListSourceSelections
} from '@/components/lib/ListSourcesDropDown'

import { useAbility, useScreenSettings } from '@/lib/authorization'
import UpgradeLink from '@/components/lib/Auth/UpgradeLink'

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0px auto'
}))

const ScrollContainer = styled('div')(() => ({
  marginTop: 40,
  scrollMarginTop: 80,
  width: '100%'
}))

const defaultSearchParams = {
  weakAKAs: WEAK_AKAS_DEFAULT,
  dobMonthsRange: FUZZY_DOB_DEFAULT_MONTHS_RANGE,
  dob: null,
  scoreThreshold: SLIDER_DEFAULT
}

const SearchPage = () => {
  const [params, setParams] = useState<IEntitySearchParams>(defaultSearchParams)
  const scrollToRef = useRef<HTMLDivElement>(null)
  const [selectedHit, setSelectedHit] = useState<number | null>(null)
  const [expand, setExpand] = useState(false)
  const [searchActive, setSearchActive] = useState(false)
  const setNotification = useNotification()
  const { isPremium } = useScreenSettings()
  const ability = useAbility()
  const queryParams = new URLSearchParams(useLocation().search)
  const queryListSourceIDs = queryParams.get('listSourceIDs')
  const queryCategoryIDs = queryParams.get('categoryIDs')
  let selectedOptions = undefined

  if (queryListSourceIDs || queryCategoryIDs) {
    const listSourceIDs = queryListSourceIDs?.split(',').map((x) => parseInt(x))
    const categoryIDs = queryCategoryIDs?.split(',').map((x) => parseInt(x))

    selectedOptions = {
      'list-source': new Set(listSourceIDs),
      category: new Set(categoryIDs)
    }
  }

  const listSourcesDropDownOptions = useListSourcesDropDownOptions(
    selectedOptions,
    getDisabledDropDownCategories((subject) =>
      ability.cannot('filter', 'Screen', subject)
    )
  )

  const { data, isIdle, isSuccess } = useAdvancedSearch(params, searchActive, {
    onError: () =>
      setNotification({
        message:
          'We had an issue processing your search. Please try again later.',
        variant: 'error'
      }),
    onSuccess: () => {
      // Allow the results component to render before scrolling to the ref
      setTimeout(() => {
        if (scrollToRef?.current) {
          scrollToRef.current.scrollIntoView({ behavior: 'smooth' })
        }
      }, 120)
    }
  })

  const handleSearch = (params: IEntitySearchParams) => {
    setParams(params)
    setSearchActive(true)

    if (expand) {
      setExpand(false)
    } else {
      setSelectedHit(null)
    }
  }

  const handleSelectHit = (idx: number | null) => {
    setSelectedHit(idx)
  }

  const listSourceQueryString = useMemo(() => {
    let queryString = ''

    const {
      listSourceIDs,
      listSourceCategoryIDs
    } = translateListSourceSelections(
      selectedListSourcesOptionsIDs(listSourcesDropDownOptions.options, true)
    )

    if (listSourceCategoryIDs.length) {
      queryString += `&categoryIDs=${[...listSourceCategoryIDs].join(',')}`
    }

    if (listSourceIDs.length) {
      queryString += `&listSourceIDs=${[...listSourceIDs].join(',')}`
    }

    return queryString
  }, [listSourcesDropDownOptions])

  useEffect(() => {
    if (window.location.search.includes('expand')) {
      setExpand(true)
      setSelectedHit(0)
    }
  }, [])

  return (
    <Root>
      <SearchForm
        doSearch={handleSearch}
        listSourcesDropDownOptions={listSourcesDropDownOptions}
      />
      <ScrollContainer ref={scrollToRef} data-cy='search-results-container'>
        {isSuccess && !isPremium && (
          <Alert
            data-cy='adverse-media-alert'
            variant='outlined'
            severity='warning'
          >
            Your search did not include Adverse Media or High-Risk Ownership data.
            {'  '}
            <UpgradeLink /> to find hidden risk with ownership data and search in
            real time across global new sources.
          </Alert>
        )}
        {!isIdle && (
          <SearchResults
            screenData={data}
            countries={params.country}
            entityTypes={params.entityType}
            citizenships={params.citizenships}
            selected={selectedHit !== null ? selectedHit : undefined}
            onSelect={handleSelectHit}
            listSourceQueryString={listSourceQueryString}
            isSuccess={isSuccess}
          />
        )}
      </ScrollContainer>
    </Root>
  )
}

export default withBreadcrumbs(SearchPage)
