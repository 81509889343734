import React from 'react'

import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Assignment from '@mui/icons-material/Assignment'
import FindInPage from '@mui/icons-material/FindInPage'

import { SearchSlider } from '@/components/lib/SearchSlider'
import { WeakAkasToggle, FuzzyDOBToggle } from '@/components/lib/Search'
import { useFormField } from '@/lib/hooks'
import {
  getDisabledDropDownCategories,
  ListSourcesDropDown,
  ListsDropDown,
  TOptionTree,
  useListSourcesDropDownOptions
} from '@/components/lib/ListSourcesDropDown'
import { useValidateBatchFile } from '@/lib/features/Screening'
import {
  WEAK_AKAS_DEFAULT,
  SLIDER_DEFAULT,
  FUZZY_DOB_TOGGLE_DEFAULT
} from '@/lib/constants/search'
import { UploadButton } from './UploadButton'
import { useNotification } from '@/lib/notification'
import { useAbility } from '@/lib/authorization'

const PREFIX = 'Form'

const classes = {
  chipRow: `${PREFIX}-chipRow`,
  input: `${PREFIX}-input`,
  rightIcon: `${PREFIX}-rightIcon`,
  errorChip: `${PREFIX}-errorChip`,
  successChip: `${PREFIX}-successChip`
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.chipRow}`]: {
    minHeight: '32px',
    padding: 0
  },

  [`& .${classes.rightIcon}`]: {
    marginLeft: theme.spacing(1)
  },

  [`& .${classes.errorChip}`]: {
    backgroundColor: theme.palette.error.main
  },

  [`& .${classes.successChip}`]: {
    backgroundColor: theme.palette.primary.main
  }
}))

type TFileChip = {
  isError: boolean
  file: File
  remove: () => void
}

const FileChip: React.FC<TFileChip> = ({ isError, file, remove }) => (
  <Chip
    className={isError ? classes.errorChip : classes.successChip}
    icon={<Assignment />}
    label={file.name}
    clickable
    color='primary'
    onDelete={remove}
  />
)

type TForm = {
  screen: (
    file: File | null,
    dropdownOptions: TOptionTree,
    scoreThreshold: number,
    weakAKAs: boolean,
    fuzzyDOB: boolean
  ) => void
  reset: () => void
  isLoading: boolean
  isError: boolean
  reportButton: JSX.Element
}

const Form: React.FC<TForm> = ({
  screen,
  reset,
  isLoading,
  isError,
  reportButton
}) => {
  const [file, setFile] = React.useState<File | null>(null)
  const [sliderValue, setSliderValue] = React.useState(SLIDER_DEFAULT)
  const [weakAkas, setWeakAkas] = useFormField('Weak AKAs', WEAK_AKAS_DEFAULT)
  const [fuzzyDOB, setFuzzyDOB] = useFormField(
    'Fuzzy DOB',
    FUZZY_DOB_TOGGLE_DEFAULT
  )
  const ability = useAbility()
  const listSourcesDropDownOptions = useListSourcesDropDownOptions(
    undefined,
    getDisabledDropDownCategories((subject: string) =>
      ability.cannot('filter', 'BatchSearch', subject)
    )
  )

  const setNotification = useNotification()
  const adverseMediaSelected = listSourcesDropDownOptions.adverseMediaSelected()
  const showError = (error: Error) => {
    setNotification({
      message: error.message,
      variant: 'error'
    })
  }

  const fileValidation = useValidateBatchFile(file, adverseMediaSelected, { onError: showError })
  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement

    if (element.files && element.files.length > 0) {
      setFile(element.files[0])
    }
  }

  const handleFileReset = (e: React.MouseEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement
    element.value = ''

    if (isError) {
      setFile(null)
      reset()
    }
  }

  const handleScreenClick = () => {
    screen(
      file,
      listSourcesDropDownOptions.options,
      sliderValue / 100,
      weakAkas.value,
      fuzzyDOB.value
    )
  }

  const handleDeleteChip = () => {
    setFile(null)
    reset()
  }

  const handleSliderCommitted = (
    e: React.SyntheticEvent | Event,
    v: number | number[]
  ) => {
    if (typeof v === 'number') {
      setSliderValue(v)
    }
  }

  return (
    <StyledGrid
      container
      spacing={4}
      justifyContent='space-between'
      alignItems='center'
    >
      <Grid container spacing={2} item xs={9}>
        <Grid
          item
          container
          justifyContent='flex-end'
          className={classes.chipRow}
        >
          {file !== null && (
            <FileChip
              file={file}
              isError={fileValidation.isError}
              remove={handleDeleteChip}
            />
          )}
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={12} md={6}>
            <ListSourcesDropDown
              status={listSourcesDropDownOptions.status}
              options={listSourcesDropDownOptions.options}
              toggle={listSourcesDropDownOptions.toggle}
              prune={listSourcesDropDownOptions.prune}
              clear={listSourcesDropDownOptions.clear}
              adverseMediaSelected={listSourcesDropDownOptions.adverseMediaSelected}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <ListsDropDown
              status={listSourcesDropDownOptions.status}
              options={listSourcesDropDownOptions.options}
              toggle={listSourcesDropDownOptions.toggle}
              prune={listSourcesDropDownOptions.prune}
              clear={listSourcesDropDownOptions.clear}
              adverseMediaSelected={listSourcesDropDownOptions.adverseMediaSelected}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={6}>
            <SearchSlider
              defaultValue={SLIDER_DEFAULT}
              handleChangeCommitted={handleSliderCommitted}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <FuzzyDOBToggle
              fuzzyDOBField={fuzzyDOB}
              setFuzzyDOB={setFuzzyDOB}
              allowed={true}
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <WeakAkasToggle
              weakAkasField={weakAkas}
              setWeakAkas={setWeakAkas}
              allowed={true}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item container spacing={3} xs={3}>
        <Grid item xs={12}>
          <UploadButton
            isLoading={isLoading}
            onFileSelect={handleFileSelect}
            onFileReset={handleFileReset}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            data-cy='screen-button'
            variant='contained'
            fullWidth
            onClick={handleScreenClick}
            disabled={
              isLoading ||
              file === null ||
              fileValidation.isLoading ||
              fileValidation.isError ||
              isError
            }
            color='grey'
          >
            Screen
            <FindInPage className={classes.rightIcon} />
          </Button>
        </Grid>

        <Grid item xs={12}>
          {reportButton}
        </Grid>
      </Grid>
    </StyledGrid>
  )
}

export default Form
