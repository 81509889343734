import React, { ComponentType } from 'react'
import { Route, RouteProps, useLocation, Redirect } from 'react-router-dom'
import { LocationDescriptorObject } from 'history'
import { useAuth0 } from '@auth0/auth0-react'

interface IProps extends RouteProps {
  component: ComponentType
  path: string
  signUp?: boolean
  isPublic?: boolean
}

const PrivateRoute: React.FC<IProps> = ({
  component,
  path,
  signUp = false,
  isPublic = true
}) => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const error = query.has('error')

  React.useEffect(() => {
    if (isLoading || isAuthenticated || isPublic) {
      return
    }

    const fn = async () => {
      await loginWithRedirect({
        appState: { targetUrl: window.location.pathname },
        authorizationParams: { screen_hint: signUp ? 'signup' : 'login' }
      })
    }

    if (!error) fn()
  }, [isLoading, isAuthenticated, loginWithRedirect, path, isPublic])

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  const routeComponent = (props: any) => {
    if (isAuthenticated === true || isPublic === true) {
      return React.createElement(component, props)
    }
  }

  if (error) {
    // If we've received an error message from auth0, redirect to our error page
    // with the error message
    const redirect: LocationDescriptorObject = { pathname: '/error' }
    if (query.has('error_description')) {
      redirect['search'] = `?message=${query.get('error_description')}`
    }
    return <Redirect to={redirect} />
  }

  return <Route path={path} render={routeComponent} />
}

export default PrivateRoute
