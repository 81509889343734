import React from 'react'
import { styled } from '@mui/material/styles'

import Accordion from '@mui/material/Accordion'
import AccordionSummary, {
  AccordionSummaryProps
} from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import ExpandMore from '@mui/icons-material/ExpandMore'
import { hitAuditStatus, STATUS_CLASSES } from '@/lib/helpers/hit'
import EntityCard from '@/components/lib/EntityCard'
import { getTypeName, getHitScore, getHighlightedEntity } from '@/lib/entity'

import { useAbility } from '@/lib/authorization'
import HitListItem from './HitListItem'

import { THit } from '@/lib/features/Screening'
import { ListEntry } from 'proto-js'

interface HitsListRowProps {
  idx: number
  hit: THit
  selected: boolean
  select: (idx: number | null) => void
  listSourceQueryString: string
}

interface EntityDetailsProps {
  entity: typeof ListEntry
  score: number
  type: string
}

const StyledSummary = styled(AccordionSummary, {
  shouldForwardProp: (props) => props !== 'showWatermark'
})<AccordionSummaryProps & { showWatermark: boolean }>(({ showWatermark }) => ({
  [`& .MuiAccordionSummary-content`]: {
    overflow: 'hidden'
  },
  ...(showWatermark && {
    backgroundImage: `url(${import.meta.env.VITE_APP_CDN_PATH}/media/watermark_small.svg)`,
    backgroundSize: '31%',
    backgroundPosition: 'right center',
    backgroundRepeat: 'repeat-x'
  })
}))

const AdverseMediaDetails: React.FC<EntityDetailsProps> = ({
  entity,
  score,
  type
}) => {
  const snippet = entity.getSnippet()
  let name = entity.getName().getFullName()
  const articleName = entity.getTitle()

  if (articleName && articleName !== '') {
    name = articleName
  }

  // Truncate the name if it's too long to 40 words
  function truncateName(name: string) {
    return name.split(' ').length > 40 ? name.split(' ').slice(0, 40).join(' ') + '...' : name
  }

  return (
    <HitListItem
      entityType={type}
      name={truncateName(name)}
      secondaryText={
        <span dangerouslySetInnerHTML={{ __html: snippet }}></span>
      }
      score={score}
      truncate={false}
    />
  )
}

const EntityDetails: React.FC<
  EntityDetailsProps & { highlightedEntity: typeof ListEntry }
> = ({ entity, highlightedEntity, score, type }) => {
  const listSourcesStr = entity.getListSource().getName()
  const name = highlightedEntity?.getName()?.getFullName()

  return (
    <HitListItem
      entityType={type}
      name={name}
      secondaryText={listSourcesStr}
      score={score}
    />
  )
}

const HitsListRow: React.FC<HitsListRowProps> = ({
  hit: { protobuf, classification },
  idx,
  listSourceQueryString,
  select,
  selected
}) => {
  const handleSelect = () => {
    if (selected) {
      select(null)
    } else {
      select(idx)
    }
  }

  const entity = protobuf.getListEntry()
  const entityType = getTypeName(entity)
  const score = getHitScore(protobuf)
  const statusClass: STATUS_CLASSES = hitAuditStatus(classification)
  const ability = useAbility()
  const showWatermark = ability.can('view', 'Watermark')
  const isMedia = entityType === 'media'
  const highlightedEntity = isMedia ? entity : getHighlightedEntity(protobuf)

  return (
    <Accordion
      expanded={selected}
      onChange={handleSelect}
      TransitionProps={{ unmountOnExit: true }}
      elevation={4}
    >
      <StyledSummary
        data-cy='hit-list-item'
        expandIcon={<ExpandMore />}
        className={statusClass}
        showWatermark={showWatermark}
      >
        {isMedia ? (
          <AdverseMediaDetails
            entity={entity}
            type={entityType}
            score={score}
          />
        ) : (
          <EntityDetails
            highlightedEntity={highlightedEntity}
            entity={entity}
            type={entityType}
            score={score}
          />
        )}
      </StyledSummary>
      <AccordionDetails>
        <EntityCard
          listSourceQueryString={listSourceQueryString}
          variant='plain'
          entity={highlightedEntity}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default HitsListRow
